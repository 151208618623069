<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

async function goToSupport() {
  const link = await store.dispatch('Keyvalue/get', {
    key: 'support_link',
    global: true
  })
  c.openLink(link)
}

function goHome() {
  router.push({ name: 'Home' })
}
</script>

<template>
  <Page class="justify-center">
    <div class="flex flex-col justify-center gap-4 text-center mb-8">
      <h2>Account has been locked</h2>
      <p>
        Your company account has been locked, please reach out to support to unlock your account.
      </p>
      <font-awesome-icon icon="lock" size="5x" />
      <div class="flex justify-center gap-4">
        <Btn severity="tertiary" size="lg" @click="goToSupport" class="mt-10"> Contact Support</Btn>
        <Btn severity="tertiary" size="lg" @click="goHome" class="mt-10"> Home</Btn>
      </div>
    </div>
  </Page>
</template>

<style scoped lang="scss"></style>
